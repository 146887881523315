<template>
    <div>
        <div class="mt-3 ml-1 vx-row">
            <div class="mb-6 vx-row" style="width:70%">
                <div class="flex items-center w-full vx-col sm:w-1/4">
                    <span>Warehouse</span>
                </div>
                <div class="w-full vx-col sm:w-3/4">
                    <CustomMSelect base-url="/api/wms/v1/wms/inbound/receive/purchase-order/receive/warehouses" label="name" track-by="name" @selected="(val) => {
                        this.selectedSrcWarehouse = val
                    }"></CustomMSelect>
                </div>
            </div>
            <div class="mb-6 vx-row" style="width:70%">
                <div class="flex items-center w-full vx-col sm:w-1/4">
                    <span>Delivery Date</span>
                </div>
                <div class="w-full vx-col sm:w-3/4">
                    <datepicker name="date" :inline="false" v-model="deliveryDate" placeholder="Select Date" :clearButton="true" :cleared="() => {
                        deliveryDate = null
                    }">
                    </datepicker>
                </div>
            </div>
            <div class="mb-6 vx-row" style="width:70%">
                <div class="flex items-center w-full vx-col sm:w-1/4">
                    <span>Shipment Number</span>
                </div>
                <div class="w-full vx-col sm:w-3/4">
                    <CustomMSelect base-url="/api/wms/v1/inbound/pod-return/shipment-numbers" label="code" track-by="code" :status="'Complete'"
                    @selected="(val) => {
                        this.selectedNumber = val
                    }"></CustomMSelect>
                </div>
            </div>
            <div class="mb-6 vx-row" style="width:70%">
                <div class="flex items-center w-full vx-col sm:w-1/4">
                </div>
                <div class="w-full vx-col sm:w-3/4">
                    <vs-button color="primary" @click="handleFilter()">Filter</vs-button>
                </div>
            </div>
        </div>
        <div class="mt-3 vx-row">
            <div class="w-full vx-col mb-base">
                <Table :draw="draw"
                    status="Complete"
                    :source-warehouse-id="(this.selectedSrcWarehouse) ? this.selectedSrcWarehouse.id : 0"
                    :shipment-number="(this.selectedNumber) ? this.selectedNumber.id : 0"
                    :delivery-date="(this.deliveryDate) ? this.deliveryDate : ''">
                </Table>
            </div>
        </div>
    </div>
</template>
<script>
import CustomMSelect from "@/components/CustomMultiSelect.vue";
import Datepicker from "vuejs-datepicker";
import Table from "./TableReceive.vue";
export default {
    components: {
        CustomMSelect,
        Datepicker,
        Table
    },
    data() {
        return {
            activeTab: "Complete",
            draw: 0,
            selectedSrcWarehouse: null,
            selectedNumber: null,
            deliveryDate: null,
        }
    },    
    methods: {
        handleFilter() {
            this.draw++;            
        },
    },
    mounted(){
        sessionStorage.setItem('activeTabPODR', "2")
    },
    watch: {
        selectedSrcWarehouse(v) {
            this.draw++
            console.log("================")
            console.log(v.id)
        }
    }
}
</script>
