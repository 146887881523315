<template>
    <div>
        <vs-table search stripe border description :sst="true" :data="table.data" :max-items="table.length"
            :total="table.total" @search="handleSearch" @change-page="handleChangePage" @sort="handleSort">
            <template slot="header">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div
                        class="flex items-center justify-between p-3 font-medium border border-solid rounded-full cursor-pointer d-theme-border-grey-light d-theme-dark-bg">
                        <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of
                            {{ this.table.total }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <vs-dropdown-menu>
                        <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
                            <span>{{ item }}</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </template>
            <template slot="thead">
                <vs-th></vs-th>
                <vs-th>Shipment Doc</vs-th>
                <vs-th>Customer Data</vs-th>
                <vs-th>SO Data</vs-th>
                <vs-th>DO Data</vs-th>
                <vs-th>List Item</vs-th>
                <vs-th>Source</vs-th>
                <vs-th>POD Status</vs-th>                
            </template>
            <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>
                        <div v-if="checkTab != 'Complete'" class="flex vx-input-group">
                            <vs-button v-if="checkTab == 'Receive'" size="small" color="warning" icon-pack="feather" icon="icon-edit" title="Edit" style="margin-right: 5px" @click="handleEditReceive(tr.id)" />
                            <vs-button v-if="checkTab == 'PutAway'" size="small" color="warning" icon-pack="feather" icon="icon-edit" title="Edit" style="margin-right: 5px" @click="handleEditPutaway(tr.id)" />
                            <vs-button size="small" color="success" icon-pack="feather" icon="icon-check" title="Confirm" style="margin-right: 5px" @click="handleConfirm(tr.id)" />
                        </div>
                        <div v-else class="flex vx-input-group">
                            <vs-button size="small" color="#9C9C9C" icon-pack="feather" icon="icon-eye" title="View" style="margin-right: 5px" @click="handleEditPutaway(tr.id)" />                            
                        </div>
                    </vs-td>
                    <vs-td>
                        <div>Code : {{ tr.pod_code }}</div>
                        <div>Date : {{ formatDate(tr.pod_date) }}</div>
                    </vs-td>
                    <vs-td>
                        <div>Code : {{ tr.cus_code }}</div>
                        <div>Name : {{ tr.cus_name }}</div>
                    </vs-td>
                    <vs-td>
                        <div>Code : {{ tr.so_code }}</div>
                        <div>Warehouse : {{ tr.wh_code }} - {{ tr.wh_name }}</div>
                    </vs-td>
                    <vs-td>
                        <div>Code : {{ tr.do_code }}</div>
                    </vs-td>
                    <vs-td>
                        <div>{{ tr.item_names }}</div>
                    </vs-td>
                    <vs-td>
                        <div>{{ tr.source }}</div>
                    </vs-td>
                    <vs-td>
                        <div v-if="tr.status_delivery == 2">Partial Complete</div>
                        <div v-if="tr.status_delivery == 4">Failed Complete</div>
                        <div v-if="tr.status_delivery == 5">Failed Redeliver</div>
                    </vs-td>                    
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage" />
    </div>
</template>

<script>
import moment from "moment";
export default {
    components: {},
    props: {
        draw: {
            type: Number,
        },
        sourceWarehouseId: {
            type: Number,
        },
        shipmentNumber: {
            type: Number,
        },
        deliveryDate: {
            type: String,
        },
        status: {
            type: String,
        },
    },
    data() {
        return {
            deleteId: null,
            table: this.tableDefaultState(),
            checkedAll: false,
            checked: [],            
            timeOuts: null,
        };
    },
    methods: {
        tableDefaultState() {
            return {
                data: [],
                length: 10,
                page: 1,
                search: "",
                order: "",
                sort: "desc",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            if (this.timeOuts) { clearTimeout(this.timeOuts) }
            this.timeOuts = setTimeout(this.getData, 900);
        },
        handleChangePage(page) {
            console.log("change page")
            this.table.page = page;
            if (this.draw != 0) {
                this.getData();
            }
        },
        handleSort(key, active) {
            console.log("handle sort")
            this.table.order = key;
            this.table.sort = active;
            this.getData();
        },
        handleChangelength(val) {
            console.log("handle change length")
            this.table.length = val == "All" ? this.table.total : val;
            this.table.page = 1;
            this.getData();
        },
        getData() {
            console.log("xxxxxxxxxxxxxxxx")
            console.log("get data", this.sourceWarehouseId)
            this.$vs.loading();
            this.$http
                .get(this.$store.state.inbound.podReturn.baseUrlPath + "/data-receive", {
                    params: {
                        length: this.table.length,
                        page: this.table.page,
                        query: this.table.search,
                        order: this.table.order,
                        sort: this.table.sort,
                        warehouse_id: this.sourceWarehouseId,
                        delivery_date: this.deliveryDate != "" ? moment(this.deliveryDate).format('YYYY-MM-DD') : "",
                        shipment_number: this.shipmentNumber,
                        status: this.status,
                    },
                })
                .then((resp) => {
                    this.$vs.loading.close();
                    if (resp.code == 200) {      
                        this.table.total = resp.data.total_record;
                        this.table.totalPage = resp.data.total_page;
                        this.table.totalSearch = resp.data.total_record_search;
                        this.table.length = resp.data.total_record_per_page;
                        // Process records to ensure unique item_names
                        this.table.data = resp.data.records.map(record => {
                            record.item_names = this.getUniqueItems(record.item_names);
                            return record;
                        });
                        this.setStartEnd();
                    }
                });
        },
        getUniqueItems(items) {
            if (typeof items === 'string') {
                items = items.split(','); // Assuming items are comma-separated
            }
            const uniqueItems = [...new Set(items)];
            return uniqueItems.join(', '); // Return a string with unique items
        },
        setStartEnd() {
            let valStart = this.table.length * this.table.page - this.table.length + 1;

            if (valStart > this.table.total) {
                valStart = 1;
            }
            if (this.table.total == 0) {
                valStart = 0;
            }
            let valEnd = this.table.length * this.table.page;

            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }

            if (
                this.table.totalSearch < this.table.total &&
                this.table.search != ""
            ) {
                valEnd = this.table.totalSearch;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
        },                
        handleEditReceive(podID){
            this.$router.push({
                name: this.$store.state.inbound.podReturn.baseRouterName + ".form-receive",
                params: { 
                    id: podID,
                    tab: this.status
                },
            });
        },
        handleEditPutaway(podID){
            this.$router.push({
                name: this.$store.state.inbound.podReturn.baseRouterName + ".form-putaway",
                params: { 
                    id: podID,
                    tab: this.status
                },
            });
        },
        handleConfirm(podID){  
            this.$vs.dialog({
                type: "confirm",
                color: "primary",
                title: "Confirmation",
                text: "Are you sure Confirm this data",
                acceptText: "Yes, sure",
                accept: () => {
                    this.$vs.loading();
                    this.$http
                        .get(this.$store.state.inbound.podReturn.baseUrlPath + "/confirm", {
                            params: {
                                id: podID,
                                tab: this.status,                        
                            },
                        })
                        .then((resp) => {                    
                            if (resp.code == 200) {      
                                this.$vs.loading.close();
                                this.$vs.notify({
                                    color: "success",
                                    title: "Success",
                                    text: resp.message,
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                                this.draw++
                            } else {
                                this.$vs.loading.close();
                                this.$vs.notify({
                                    color: "danger",
                                    title: "Failed",
                                    text: resp.message,
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            }
                        });
                },
            });
        }
    },
    mounted() { },
    watch: {
        draw() {
            this.getData();
        },
    },
    computed: {
        checkTab (){
            return this.status;
        },
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                if (!momentDate.isValid()) {
                    return "";
                }
                return momentDate.format('DD MMM YYYY');
            };
        },
        formatLocalNumber: () => {
            return (val) => {
                return Number(val).toLocaleString("id-ID");
            };
        },
    },
};
</script>